import React from 'react';
import Logo from '../../assets/Index/logo2.png';
import { FaLinkedinIn, FaInstagram, FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import GetStarted from './GetStarted';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {

  const { t } = useTranslation();

  return (
    <>
      <GetStarted />
      <section className='grid md:grid-cols-12 grid-cols-2 py-24 bottom-0 bg-white'>

        <div className='col-start-2 col-span-10 justify-around md:flex hidden'>

          <div className='mx-2'>
            <img alt='Fortimy Icon' src={Logo} />
          </div>

          <div className='mx-2'>
            <h1 className='xl:text-3xl md:text-xl font-bold my-5'>{t('Footer.Title1Company')}</h1>
            <ul>
              <li><Link to="/businesses#process-overview" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.Title2Item1Process')}</Link></li>
              <li><Link to="/businesses#step-by-step" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.Title2Item2StepByStep')}</Link></li>
              <li><Link to="/businesses#why-fortimy" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.why_fortimy')}</Link></li>
              <li><Link to="/businesses#testimonials" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.testimonials')}</Link></li>
            </ul>
          </div>

          <div className='mx-2'>
            <h1 className='xl:text-3xl md:text-xl font-bold my-5'>{t('Footer.pricing')}</h1>
            <ul>
              <li><Link to="/pricing" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.plans')}</Link></li>
            </ul>
          </div>

          <div className='mx-2'>
            <h1 className='xl:text-3xl md:text-xl font-bold my-5'>{t('Footer.Title4Legal')}</h1>
            <ul>
              <li><Link target="_blank" to="/terms-and-conditions" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.Title4Item1TermsConditions')}</Link></li>
              <li><Link target="_blank" to="/privacy-policy" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.Title4Item2PrivacyPolicy')}</Link></li>
            </ul>
          </div>

          <div className='mx-2'>
            <h1 className='xl:text-3xl md:text-xl font-bold my-5'>{t('Footer.contacts')}</h1>
            <ul>
              {/* <li><Link to={`tel:${t('Footer.phone_number').replaceAll(' ', '')}`} className='xl:text-xl md:text-lg'>{t('Footer.phone_number')}</Link></li>
              <li><span className='text-sm'>{t('Footer.national_call')}</span></li> */}
              <li><Link to={`mailto:${t('Footer.email')}`} className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.email')}</Link></li>
            </ul>
            <div className='flex mt-5 gap-2'>
              <Link
                to={"https://www.linkedin.com/company/fortimy/"}
                target='_blank'
                className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
              >
                <FaLinkedinIn />
              </Link>
              <Link
                to={"https://www.instagram.com/fortimypt/"}
                target='_blank'
                className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
              >
                <FaInstagram />
              </Link>
              <Link
                to={"https://www.facebook.com/fortimypt"}
                target='_blank'
                className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
              >
                <FaFacebookF />
              </Link>
              <Link
                to={"https://www.youtube.com/@fortimypt"}
                target='_blank'
                className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
              >
                <FaYoutube />
              </Link>
              <Link
                to={"https://x.com/fortimypt"}
                target='_blank'
                className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
              >
                <FaXTwitter />
              </Link>
            </div>
          </div>

        </div>

        <div className='md:hidden col-span-1 flex flex-col mx-5'>

          <div className='w-full'>
            <h1 className='font-bold my-2 text-2xl'>{t('Footer.Title1Company')}</h1>
            <ul>
              <li className='text-lg pointer-events-none'><Link to="/businesses#process-overview">{t('Footer.Title2Item1Process')}</Link></li>
              <li className='text-lg pointer-events-none'><Link to="/businesses#step-by-step">{t('Footer.Title2Item2StepByStep')}</Link></li>
              <li className='text-lg pointer-events-none'><Link to="/businesses#why-fortimy">{t('Footer.why_fortimy')}</Link></li>
              <li className='text-lg pointer-events-none'><Link to="/businesses#testimonials">{t('Footer.testimonials')}</Link></li>
            </ul>
          </div>

        </div>

        <div className='md:hidden col-start-2 col-span-1 flex flex-col justify-start text-start mx-5'>

          <div className='w-full'>
            <h1 className='text-2xl font-bold my-2'>{t('Footer.pricing')}</h1>
            <ul>
              <li className='text-lg'><Link to="/pricing" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.plans')}</Link></li>
            </ul>
          </div>

          <div className='w-full'>
            <h1 className='text-2xl font-bold my-2'>{t('Footer.Title4Legal')}</h1>
            <ul>
              <li><Link target="_blank" to="/terms-and-conditions" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.Title4Item1TermsConditions')}</Link></li>
              <li><Link target="_blank" to="/privacy-policy" className='xl:text-xl md:text-lg pointer-events-none'>{t('Footer.Title4Item2PrivacyPolicy')}</Link></li>
            </ul>
          </div>

          <div className='w-full'>
            <h1 className='text-2xl font-bold my-2'>{t('Footer.contacts')}</h1>
            <ul>
              {/* <li><Link to={`tel:${t('Footer.phone_number').replaceAll(' ', '')}`} className='text-lg'>{t('Footer.phone_number')}</Link></li>
              <li><span className='text-sm'>{t('Footer.national_call')}</span></li> */}
              <li><Link to={`mailto:${t('Footer.email')}`} className='text-lg pointer-events-none'>{t('Footer.email')}</Link></li>
            </ul>
          </div>

        </div>

        <div className='flex justify-center w-full col-span-2 md:hidden mt-5 gap-2'>
          <Link
            to={"https://www.linkedin.com/company/fortimy/"}
            target='_blank'
            className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
          >
            <FaLinkedinIn />
          </Link>
          <Link
            to={"https://www.instagram.com/fortimypt/"}
            target='_blank'
            className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
          >
            <FaInstagram />
          </Link>
          <Link
            to={"https://www.facebook.com/fortimypt"}
            target='_blank'
            className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
          >
            <FaFacebookF />
          </Link>
          <Link
            to={"https://www.youtube.com/@fortimypt"}
            target='_blank'
            className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
          >
            <FaYoutube />
          </Link>
          <Link
            to={"https://x.com/fortimypt"}
            target='_blank'
            className='flex items-center justify-center shadow-custom rounded-full bg-primary w-12 h-12 text-white text-3xl pointer-events-none'
          >
            <FaXTwitter />
          </Link>
        </div>

      </section>
    </>
  )
}

export default Footer
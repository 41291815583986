import { useTranslation } from 'react-i18next';

const MaintenancePopup = () => {

    const { t } = useTranslation();

    return (
        <div className="fixed bg-black/30 pointer-events-none h-screen w-screen z-[999] flex justify-center items-center">
            <div className="rounded-3xl w-3/4 h-3/4 lg:max-w-xl xl:max-w-3xl xl:max-h-[760px] flex items-center justify-center flex-col text-center text-white p-5 md:p-10 lg:p-20 gap-6 m-0 bg-cover md:bg-contain"
                style={{
                    backgroundImage: "url('bg-orange.png')",
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                {/* <img src="bg-orange.png" alt="" className='absolute -z-[1] object-contain w-full h-full' /> */}
                
                <img src="tool.png" alt="" className='w-20 h-20 md:w-28 md:h-28' />
                <div className='text-xl md:text-3xl'>
                    {t('Popups.maintenance.we_are_improving_for_you')}
                </div>
                <p className='text-base md:text-xl'>
                    {t('Popups.maintenance.we_received_your_feedback')}
                </p>
            </div>
        </div>
    );
}

export default MaintenancePopup;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/Logo.png';
import useAuthContext from '../Config/AuthContext';
import { useAlert } from '../../contexts/AlertContext';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';


const Index = () => {

  const { setAuth } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const { login, errors } = useAuthContext();
  const { toggleLoader, triggerPopup } = useAlert();

  const { t } = useTranslation();

  const submitForm = async event => {
    toggleLoader(true);
    event.preventDefault();
    const response = await login({ email, password })
    if(response.status === 204) {
      triggerPopup('Authentication.authSuccess');
    }
    if(response.status === 401) {
      triggerPopup('Authentication.unauthorized');
    }
    toggleLoader(false); 
  }

  return (
    <>
      <section className='w-full flex flex-col p-10 pt-20 md:pt-10'>
        <div className='w-full flex md:flex-row flex-col justify-center items-center p-10'>
          <div className=' font-bold text-3xl md:pt-2 md:pr-3'>{t('Authentication.logInTo')}</div>
          <img className='w-[9rem]' src={Logo} alt='Logo' />
        </div>
        <div className='md:grid md:grid-cols-10 flex flex-col'>
          <div className='flex flex-col xl:col-start-5 xl:col-end-7 lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9'>

            <form className='' onSubmit={submitForm}>
              <input required autoFocus value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" className='w-full my-2 p-2 border-[1px] rounded-md border-fifth placeholder:text-xl placeholder:text-black' placeholder='Email' />
              {errors?.email && <span className='text-primary text-sm'>{errors?.email[0]}</span>}
              <div className='flex items-center w-full relative'>
                <input value={password} onChange={(e) => { setPassword(e.target.value) }} type={showPassword ? "text" : "password"} className='w-full my-2 p-2 border-[1px] rounded-md border-fifth placeholder:text-xl placeholder:text-black' placeholder={t('Authentication.password')} />
                <button type='button' onClick={() => {showPassword ? setShowPassword(false) : setShowPassword(true)}} className='absolute right-3 text-xl text-black'>{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</button>              
              </div>
              {errors?.password && <span className='text-primary text-sm'>{errors?.password[0]}</span>}
              <div className='flex my-2'>
                <input className='accent-black mr-2' type="checkbox" />
                <p className='text-lg'>{t('Authentication.rememberMe')}</p>
              </div>
              <button type='submit' className='w-full font-bold text-white text-xl bg-primary p-2 shadow-lg rounded-full my-2'>{t('Authentication.login')}</button>
              <div className='w-full justify-center flex my-2'>
                <Link to="/forgot-password" className='pointer-events-none'><div href='#' className='text-primary text-base underline text-center pointer-events-none'>{t('Authentication.forgotPassword')}</div></Link>
              </div>
            </form>

            <div className='w-full flex justify-center my-2'>
              <p className='text-black text-xl'>{t('Authentication.or')}</p>
            </div>

            <div className='w-full my-2 mb-10 flex'>
              <Link to="/pricing" className='p-2 w-full text-center text-white text-xl font-bold bg-black shadow-md rounded-full pointer-events-none'>{t('Authentication.newCustomer')}<br />{t('Authentication.register')}</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Index